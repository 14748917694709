import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";

import Page404 from "../views/404.vue";
import PageHome from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: PageHome
  },
  { path: "/:catchAll(.*)", component: Page404, meta: { title: "Not Found" } }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

trackRouter(router);

router.beforeEach((to, _from, next) => {
  const title = to.meta.title;
  if (title) {
    document.title = `Thibault Deboutte | ${to.meta.title}`;
  } else {
    document.title = "Thibault Deboutte";
  }
  next();
});

export default router;
