import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueGtag from "vue-gtag-next";
const gtagOptions = {
  property: {
    id: "UA-64565853-9"
  }
};

createApp(App)
  .use(store)
  .use(router)
  .use(VueGtag, gtagOptions)
  .mount("#app");
