<template>
  <div class="h-screen flex justify-center items-center">
    <div class="text-center">
      <div class="flex justify-center mb-3">
        <span
          class="iconify text-6xl"
          data-icon="twemoji:crying-face"
          data-inline="false"
        ></span>
      </div>
      <h1 class="text-6xl -mb-4">404</h1>
      <p class="text-xl text-gray-600 mb-4">Page not found</p>
      <HomeLink />
    </div>
  </div>
</template>

<script>
import HomeLink from "../components/404/HomeLink";
export default {
  components: {
    HomeLink
  }
};
</script>
