<template>
  <header class="h-screen w-screen flex flex-col justify-center">
    <div class="header-content text-center">
      <h1 class="text-5xl md:text-6xl">
        Hi, I'm <span class="color-theme">Thibault Deboutte</span>.
      </h1>
      <h3 class="text-2xl md:text-3xl mb-3">
        Information Engineering Technology student @ UGent
      </h3>
      <div class="header-links">
        <HeaderLink
          v-for="link in links"
          v-bind:key="link.name"
          :link="link.url"
          :icon="link.icon"
          :name="link.name"
          v-on:click="onclick(link)"
        />
      </div>
    </div>
  </header>
</template>

<script>
import HeaderLink from "./HeaderLink.vue";
export default {
  components: {
    HeaderLink
  },
  computed: {
    links() {
      return this.$store.state.links;
    }
  },
  methods: {
    onclick(link) {
      console.log(link);
    }
  }
};
</script>
