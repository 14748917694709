<template>
  <div class="home">
    <Header />
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";

export default {
  name: "Home",
  components: {
    Header
  }
};
</script>
