<template>
  <router-link
    class="color-theme flex items-center justify-center block"
    :to="{ name: 'Home' }"
  >
    <span
      class="iconify color-theme"
      data-icon="mdi:home"
      data-inline="false"
    ></span>
    <span>Return home</span>
  </router-link>
</template>
