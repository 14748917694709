import { createStore } from "vuex";

export default createStore({
  state: {
    links: [
      {
        name: "Email",
        order: 1,
        url: "mailto:thibault.deboutte@ugent.be",
        icon: "mdi:email",
        popup: true
      },
      {
        name: "LinkedIn",
        order: 2,
        url: "https://www.linkedin.com/in/thibault-deboutte/",
        icon: "mdi:linkedin"
      }
    ],
    education: []
  },
  mutations: {
    setLinks(state, val) {
      state.links = val;
    },
    setEducation(state, val) {
      state.education = val;
    }
  },
  actions: {},
  modules: {}
});
