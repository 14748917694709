<template>
  <a :href="link" target="_blank" class="text-4xl m-1 inline-block color-theme">
    <span class="iconify" :data-icon="icon"></span>
    <span class="sr-only">{{ name }}</span>
  </a>
</template>

<script>
export default {
  props: ["link", "icon", "name"]
};
</script>
