<template>
  <router-view />
</template>

<script>
export default {
  mounted() {
    console.log(
      "%c ",
      "font-size:600px; background:url(https://i.ibb.co/gmxv5cP/console.gif) no-repeat; backdround-size: contain;"
    );
    // this.$store.dispatch("fetchLinks");
  }
};
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");

#app {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.color-theme {
  color: #007bff;
}
</style>
